<template>
    <ListReservations></ListReservations>
</template>
  
  <script>

import ListReservations from "@core/layouts/components/admin-components/ListReservations.vue";

export default {
    name: "Reservations",

    components: {
        ListReservations,
    }
}
</script>
  
  <style scoped>
  </style>