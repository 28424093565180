<template>

  <div v-if="this.currentBooking">
    <h4 style="text-align: center;" v-if="this.currentBooking.id" class="mb-2">Ticket number: {{ this.currentBooking.id
      }} <span> - Driver name: {{ getDriverNameBooking(this.currentBooking) }}</span> <span
        v-if="this.currentBooking.status_code != null">- Status Booking:</span> {{
          this.currentBooking.status_code != null ? this.currentBooking.status_code.status : "" }} <span v-if="eta != null">
        -
        ETA</span> {{ eta }}</h4>


    <!-- <h4 v-if="driver" class="mb-2">Driver name: {{ driver.name }} {{ driver.lastname }}</h4> -->
    <template v-if="center.lat != null && center.lng != null">
      <GmapMap :center='center' :zoom='14' style='width:100%;  height: 75vh;'>
        <gmap-marker :position="center" :clickable="true" :icon="imageIconDriver"></gmap-marker>
        <template v-if="this.currentBooking.status_code">
          <gmap-marker
            v-if="this.currentBooking.status_code.code === 0 || this.currentBooking.status_code.code === 2 || this.currentBooking.status_code.code === 5 || this.currentBooking.status_code.code === 16"
            :position="locationPassanger" :clickable="true" :icon="imageIconPassanger"></gmap-marker>
        </template>
        <gmap-marker :position="locationDestiny" :clickable="true" :icon="imageIconDestiny"></gmap-marker>
      </GmapMap>
    </template>

    <template v-if="center.lat == null && center.lng == null">
      <h4 style="text-align: center;margin-top: 90px;margin-bottom: 70px">No data to show</h4>
    </template>

  </div>
</template>
<script>
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getCentralTime, getMessageError } from "@core/utils/utils";
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2, addTimes, timeToSecs, secsToTime } from "@core/utils/dates";
import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import { selectedRow } from "@core/utils/cssStyle";
import Echo from 'laravel-echo'
export default {
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BFormGroup,
    flatPickr,
    vSelect,
    BFormInput,
    moment,
    BImg
  },
  props: {
    bookingId: null,
  },
  data() {
    return {
      center: { lat: null, lng: null },
      locationPassanger: { lat: null, lng: null },
      locationDestiny: { lat: null, lng: null },
      echoCliet: null,
      currentChannel: null,
      isDev: null,
      laravelEchoPort: null,
      channelName: null,
      imageIconDriver: null,
      imageIconPassanger: null,
      imageIconDestiny: null,
      currentStatusBooking: null,
      currentBooking: null,
      listStatus: [],
      eta: null,
    };
  },
  methods: {
    listenChannel() {
      this.currentChannel = this.echoCliet.channel(this.channelName);

      let me = this;

      this.currentChannel.listen('.bookingTracking', function (data) {
        try {
          if (data.lat !== 0 && data.long !== 0) {
            me.center.lat = parseFloat(data.lat);
            me.center.lng = parseFloat(data.long);
            me.eta = data.eta;
          }

          me.setStatus(data.bookingStatus);

        } catch (error) {
        }

      });
    },
    setStatus(currentCode) {
      try {
        currentCode = parseInt(currentCode);
        this.currentBooking.status_code = this.listStatus.find(x => x.code == currentCode);
      } catch (error) {

      }
    },
    async GetInfoBroadcastingServer() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`admin/get_info_broadcasting_server`)
        .then((response) => {
          this.isDev = response.data.data.isDev;
          this.laravelEchoPort = response.data.data.laravelEchoPort;
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.statusSms = "DOWN";
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async loadData() {
      this.setMapIcon();
      await this.GetListStatus();
      await this.getInfoBooking();
      this.setLocationPassanger(this.currentBooking.from);
      await this.GetInfoBroadcastingServer();
      this.loadLaravelEchoClient();
      if(this.currentBooking.status!=4){
        this.loadChannel();
      }  
      this.loadDestiny();
    },
    loadDestiny() {
      try {
        let coordinates = JSON.parse(this.currentBooking.to).coordinate.split(",");
        this.locationDestiny.lat = parseFloat(coordinates[0]);
        this.locationDestiny.lng = parseFloat(coordinates[1]);
      } catch (error) {
      }

    },
    loadChannel() {
      this.channelName = "booking." + this.currentBooking.id + "." + this.currentBooking.selfpay_id + "." + this.currentBooking.driver_id;

      if (this.isDev == "yes") {
        this.channelName = "dev." + this.channelName;
      }
      this.listenChannel();

    },
    loadLaravelEchoClient() {
      let urlEnv = process.env.VUE_APP_URL;
      let hostnameBroadcast = "https://" + urlEnv.split('/')[2];
      this.echoCliet = new Echo({
        broadcaster: 'socket.io',
        host: hostnameBroadcast + ":" + this.laravelEchoPort,
      });
    },
    setMapIcon() {
      let currentUrl = document.URL
      let urlIconDriver = currentUrl.split('/')[0] + "//" + currentUrl.split('/')[2] + "/ima/logoDriverMap.png";
      this.imageIconDriver = urlIconDriver;
      let urlIconPassanger = currentUrl.split('/')[0] + "//" + currentUrl.split('/')[2] + "/ima/logoPassangerMap.png";
      this.imageIconPassanger = urlIconPassanger;
      let urlIconDestiny = currentUrl.split('/')[0] + "//" + currentUrl.split('/')[2] + "/ima/logoDestinyMap.png";
      this.imageIconDestiny = urlIconDestiny;
    },
    getlatFrom(item) {
      let res = null;
      try {
        res = item.coordinate.split(",")[0];
      } catch (error) {

      }
      return res;
    },
    getlongFrom(item) {
      let res = null;
      try {
        res = item.coordinate.split(",")[1];
      } catch (error) {

      }
      return res;
    },
    setLocationPassanger(from) {
      from = JSON.parse(from);
      this.locationPassanger.lat = parseFloat(this.getlatFrom(from));
      this.locationPassanger.lng = parseFloat(this.getlongFrom(from));
    },
    async getInfoBooking(showModal = true) {
      if (showModal === true) {
        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

      }

      await this.$http
        .get(`admin/panel/booking/${this.bookingId}/info`)
        .then((response) => {
          this.currentBooking = response.data.data;
          //this.calculatePrice();
          if (showModal === true) {
            this.$swal.close();
          }

        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async GetListStatus() {

      await this.$http
        .get(`get_list_status`)
        .then((response) => {
          this.listStatus = response.data;
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getDriverNameBooking(item) {
      let res = "";

      try {
        let firstName = item.driver.name === null ? "" : item.driver.name;
        let lastName = item.driver.lastname === null ? "" : item.driver.lastname;
        res = firstName + " " + lastName;
      } catch (error) {

      }
      return res;
    },

  },
  mounted() {
    this.loadData();
  },
};
</script>